import React from "react";
import ContactSection from "~sections/contact/ContactOne/ContactSection";
import FooterFive from '~sections/agency/FooterFive'
import { PageWrapper } from "~components/Core";
const header = {
    headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
    containerFluid: false,
    // buttonBlock: (
    //   <HeaderButton
    //     className="ms-auto d-none d-xs-inline-flex"
    //     btnOneText="Login"
    //     btnTwoText="Get Started For Free"
    //     mr="15px"
    //     mrLG="0"
    //   />
    // ),
}

export default function contactOne() {
    return (
        <PageWrapper innerPage={true}>
            <ContactSection />
            <FooterFive />
        </PageWrapper>
    )
}